import { Hero } from "../components/Hero";
import { Offerings } from "../components/Offerings";
import coverImage from "../assets/cover.png";

const offerings = [
  {
    top: "BSL Level",
    main: "1",
    link: "#",
  },
  {
    top: "BSL Level",
    main: "2",
    link: "#",
  },
  {
    top: "BSL Level",
    main: "3",
    link: "#",
  },
  {
    top: "An Introduction To",
    main: "BSL",
    link: "#",
  },
  {
    top: "MeSign",
    main: "Refresher Course",
    link: "#",
  }
];

export const Home = () => {
  return (
    <>
      <Hero />
      <img width="100%" src={coverImage} alt="Lorna cover" />
      <Offerings offerings={offerings} />
    </>
  );
};
