import awardImage from "../assets/award-img.png";
import deafAwarenessImage from "../assets/deaf-awareness.png";
import learnBslImage from "../assets/learn-bsl.jpeg";
import loveBslImage from "../assets/love-bsl.png";

export const About = () => {
  return (
    <div className="container p-5 d-flex flex-column gap-5">
      <div className="with-divider mb-5 display-3 text-center h1 hero-text mb-5">
        About Me
      </div>
      <p className="fs-5">
        Hello there! I'm thrilled to welcome you to my world, where British Sign
        Language takes center stage. My journey as a passionate advocate and
        educator in the Deaf community has been both inspiring and empowering.
        Allow me to share my story, my dedication to education, and my vision
        for bridging the gap between the Deaf and hearing worlds.
      </p>
      <div className="d-flex flex-column flex-lg-row gap-5">
        <div className="w-100 w-lg-50 align-self-start">
          <div className="display-6 with-divider text-center mb-5">
            Early Life and Embracing BSL
          </div>
          <p className="fs-5">
            I was born into a hearing family, yet my native language has always
            been British Sign Language. This unique experience highlighted the
            profound importance of communication between the Deaf and hearing
            communities. Motivated by my personal journey, I embarked on a
            mission to turn this gap into a bridge that fosters mutual
            understanding and connection.
          </p>
        </div>
        <div className="w-100 w-md-50 text-center">
          <img src={loveBslImage} width="75%" alt="Artistic butterfly" />
        </div>
      </div>

      <div className="d-flex flex-column-reverse flex-lg-row gap-5">
        <div className="w-100 w-lg-50 text-center">
          <img src={deafAwarenessImage} width="75%" alt="Deaf Awareness" />
        </div>
        <div className="w-100 w-lg-50 align-self-end">
          <div className="display-6 with-divider text-center mb-5">
            Passion for Teaching BSL
          </div>
          <p className="fs-5">
            For over three decades, teaching BSL has been my passion. I've had
            the privilege of reaching learners across various domains, from
            academia to the corporate sphere. My journey started within
            traditional educational institutions and extended to collaborating
            with renowned brands such as Tesco, Specsavers, and STV. Through my
            teaching, I've demonstrated that sign language isn't just for
            individuals; it's a tool that enriches organizations and society as
            a whole.
          </p>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row gap-5">
        <div className="w-100 w-lg-50 align-self-start">
          <div className="display-6 with-divider text-center mb-5">
            Recognition and Proud Achievements
          </div>
          <p className="fs-5">
            My commitment to teaching BSL has been recognized by Signature, a
            prestigious BSL awarding body based in England. Their awards stand
            as a testament to the effectiveness of my teaching methods and my
            unwavering dedication to nurturing BSL proficiency.
          </p>
        </div>
        <div className="w-100 w-lg-50 text-center">
          <img width="75%" src={awardImage} alt="BSL Award" />
        </div>
      </div>

      <div className="d-flex flex-column-reverse flex-lg-row gap-5">
        <div className="w-100 w-lg-50 text-center">
          <img width="75%" src={learnBslImage} alt="boat" />
        </div>
        <div className="w-100 w-lg-50 align-self-end">
          <div className="display-6 with-divider text-center mb-5">
            A Multilingual Haven
          </div>
          <p className="fs-5">
            My personal life embodies the harmony of multiple languages. I share
            my life with a hearing husband and together, we've cultivated an
            environment where both sign language and spoken communication
            thrive. As a mother of two Coda's, I've experienced firsthand the
            power of linguistic diversity in creating an inclusive and vibrant
            home.
          </p>
        </div>
      </div>

      <p className="fs-5">
        In me, you'll find not just an educator but a living embodiment of the
        transformative potential of British Sign Language. From my childhood as
        a deaf member of a hearing family to my role today as an acclaimed BSL
        Lecturer, my journey speaks to anyone seeking meaningful connections
        beyond linguistic and cultural boundaries. Join me as we dive into the
        world of British Sign Language, where communication knows no bounds.
      </p>
    </div>
  );
};
