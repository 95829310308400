import swl from "../assets/swl.png";
import { LinkedInIcon } from "./LinkedIn";
import { FacebookIcon } from "./Facebook";
import { TwitterIcon } from "./Twitter";
import { NavLink } from "react-router-dom";
import { Button } from "./Button";

export const Hero = () => {
  return (
    <div className="hero container px-5 py-2">
      <div className="d-flex flex-column flex-md-row gap-5 justify-content-between">
        <div className="d-flex flex-column flex-md-row align-items-center gap-5">
          <div className="socials d-flex flex-row flex-md-column gap-3">
            <a
              className="cursor-pointer text-color-2"
              href="https://www.linkedin.com/in/lorna-mcintyre-31978a226"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon height="3rem" width="3rem" />
            </a>
            <a
              className="cursor-pointer text-color-2"
              href="https://twitter.com/LornaMcInt69069"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon height="3rem" width="3rem" />
            </a>
            <a
              className="cursor-pointer text-color-2"
              href="https://www.facebook.com/profile.php?id=100078416847232"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon height="3rem" width="3rem" />
            </a>
          </div>

          <div className="intro d-flex flex-column gap-3">
            <span className="h1 hero-text text-center">I'm BSL Tutor</span>
            <span className="h1 hero-text text-center">Lorna McIntyre</span>
            <p className="lead fs-5 text-justify">
            I'm a profoundly deaf BSL trainer with an experience of over 30 years in the field. I am dedicated to teaching British Sign Language (BSL) and making it accessible to all who wish to learn it. I have taught in various locations over the UK and a multitude of individuals, schools, colleges, universities, organisations & corporations ranging from emergency services, to supermarkets like Tesco, Asda, to Specsavers, film/production companies to STV and many more. I am most grateful and privileged to be able teach this beautiful language of the deaf.
            </p>
            <Button className="btn btn-primary btn-lg">
              <NavLink
                className="fs-5 link-light link-underline-opacity-0"
                to="/about"
              >
                Learn More
              </NavLink>
            </Button>
          </div>
        </div>

        <div className="hero-img w-100">
          <img width="100%" src={swl} alt="Sign with Lorna"></img>
        </div>
      </div>
    </div>
  );
};
