import { Button } from "./Button";

export const Offerings = (props) => {
  return (
    <div className="container-fluid p-5">
      <div className="h1 with-divider text-center mb-5">
        What Services I Provide
      </div>
      <div className="offerings d-flex flex-column flex-lg-row flex-wrap gap-5 align-items-center justify-content-center">
        {props.offerings &&
          props.offerings.map((offering, index) => (
            <div
              key={`offering_${index}`}
              className="card text-center bg-pink-light"
            >
              <div className="card-body d-flex gap-3 flex-column justify-content-center align-items-center">
                <h5 className="card-title fw-400 display-5">{offering.top}</h5>
                <p className="card-text fw-700 display-4">{offering.main}</p>
                <Button className="btn btn-primary btn-lg px-3">
                  <a
                    className="link-light link-underline-opacity-0"
                    href={`mailto:signwithlorna@yahoo.com&subject=Interested in "${offering.top} ${offering.main}"`}
                  >
                    Enquire
                  </a>
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
