import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";

const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "About",
    url: "/about",
  },
  {
    label: "Contact",
    url: "mailto:signwithlorna@yahoo.com",
  },
];

export const Header = () => {
  return (
    <div className="bg-pink-light w-100 fixed-top">
      <header className="container-lg px-1 px-md-5 py-2 d-flex justify-content-md-between align-items-center">
        <img className="logo" src={logo} alt="Sign with Lorna Logo" />
        <div
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="656e20b62c09f275994f73a8"
          data-style-height="52px"
          data-style-width="100%"
        >
          <a
            href="https://uk.trustpilot.com/review/signwithlorna.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            Trustpilot
          </a>
        </div>
        <nav className="d-flex gap-2 gap-lg-5">
          {links &&
            links.map((link, index) => (
              <NavLink
                key={`navlink_${index}`}
                className="fs-5 link-dark link-underline-opacity-0"
                to={link.url}
              >
                {link.label}
              </NavLink>
            ))}
        </nav>
      </header>
    </div>
  );
};
