import { LinkedInIcon } from "./LinkedIn";
import { FacebookIcon } from "./Facebook";
import { TwitterIcon } from "./Twitter";
import logo from "../assets/logo.png";

export const Footer = () => {
  return (
    <footer className="footer d-flex flex-column gap-5 p-5">
      <div className="w-100 d-flex flex-column gap-5 flex-lg-row align-contents-center justify-content-center">
        <div className="socials d-flex flex-row gap-3 align-self-center align-self-md-start">
          <a
            className="cursor-pointer text-white"
            href="https://www.linkedin.com/in/lorna-mcintyre-31978a226"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon height="3rem" width="3rem" />
          </a>
          <a
            className="cursor-pointer text-white"
            href="https://x.com/SignWithLorna"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon height="3rem" width="3rem" />
          </a>
          <a
            className="cursor-pointer text-white"
            href="https://www.facebook.com/profile.php?id=100078416847232"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon height="3rem" width="3rem" />
          </a>
        </div>
        <div className="flex-fill text-center align-self-sm-center">
          <img className="logo" src={logo} alt="Sign with Lorna Logo" />
        </div>
        <div className="align-self-center">
          <a
            className="link-light link-underline-opacity-0 fs-3"
            href="mailto:signwithlorna@yahoo.com"
          >
            signwithlorna@yahoo.com
          </a>
        </div>
      </div>
    </footer>
  );
};
