import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { Footer } from "./components/Footer";
import { About } from "./pages/About";

export const App = () => {
  return (
    <div className="main">
      <Router>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/portfolio"
              element={<div className="h1">Portfolio</div>}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};
